import React, { useEffect, useState } from 'react';
import api from '../../shared/lib/api';
import { IKunde, IRadgiver } from '../../shared/lib/types';
import { sortedKundeliste } from '../kundeHelper';
import SelectInput, { ISelectInputOption } from '../../../form/SelectInput';
import { InputElementEnum } from '../../../../shared/lib/types';
import VelgKundeListe from '../VelgKundeListe';
import { useParams } from 'react-router';
import Spinner from '../../spinner/Spinner';
import FeilmeldingSide from '../../feilside/FeilmeldingSide';
import TilbakeTil from '../Tilbake';
import toast from 'react-hot-toast';

type TParams = {
    orgnr: string;
};

export default function ImportKundeListe() {
    const { orgnr } = useParams<TParams>();
    const [kundeliste, setKundeliste] = useState<Array<IKunde>>();
    const [kundeansvarlige, setKundeansvarlige] = useState<Array<IRadgiver>>();
    const [radgivere, setRadgivere] = useState<Array<IRadgiver>>();
    const [selectedKundeBrukerId, setSelectedKundeBrukerId] = useState<string[]>([]);
    const [selectedRadgiverBrukerId, setSelectedRadgiverBrukerId] = useState<string>();
    const [loading, setLoading] = useState<boolean>(true);
    const [serverErrorCode, setServerErrorCode] = useState<number>(0);
    const onKundeSelected = (kunderAnsvarligBrukerId: Array<string>) => {
        setSelectedKundeBrukerId(kunderAnsvarligBrukerId);
    };
    const onSelectedRadgiverChange = (value: string) => {
        setSelectedRadgiverBrukerId(value);
    };
    const updateKundeAnsvarlig = () => {
        const radgiver = radgivere.find((item) => item.brukerId === selectedRadgiverBrukerId);
        let newKundeListe = [...kundeliste];

        selectedKundeBrukerId.forEach((kundeId) => {
            const kunde = kundeliste.find((item) => item.id === kundeId);
            const kundeIndex = kundeliste.findIndex((item) => item.id === kundeId);
            const updatedKunde = { ...kunde, kundeansvarlig: radgiver } as IKunde;

            newKundeListe.splice(kundeIndex, 1, updatedKunde);
        });

        setKundeliste(newKundeListe);
    };
    const onKnyttRadgiver = () => {
        if (selectedRadgiverBrukerId && selectedKundeBrukerId.length > 0) {
            setLoading(true);

            api.postKundeAnsvarlig(selectedRadgiverBrukerId, selectedKundeBrukerId)
                .then((res) => {
                    toast.success('Kunde(r) er oppdatert');
                    updateKundeAnsvarlig();
                    setSelectedRadgiverBrukerId('');
                    setSelectedKundeBrukerId([]);
                })
                .catch(() => toast.error('Kunne ikke oppdatere kunde(r)'))
                .finally(() => setLoading(false));
        } else {
            toast.error('Velg kunde(r) og en rådgiver.');
        }
    };

    useEffect(() => {
        (() => {
            Promise.all([api.importKunder(orgnr), api.getRadgivere()])
                .then((res) => {
                    if (res[0].length > 0) {
                        setKundeliste(sortedKundeliste(res[0]));
                        toast.success(`${res[0].length} kunde(r) ble importert`);
                        setRadgivere(res[1]);
                        setKundeansvarlige(res[1]);
                    }
                })
                .catch((res) => setServerErrorCode(res.status))
                .finally(() => setLoading(false));

            document.title = 'KS Komplett - Import kundeliste';
        })();
    }, [orgnr]);

    if (loading) {
        return <Spinner loading={loading} />;
    }

    if (serverErrorCode) {
        return <FeilmeldingSide errorCode={serverErrorCode} url="/kunder" />;
    }

    return (
        <div>
            <div className="page-header">
                <div className="container">
                    <h1>Tilknytt rådgiver</h1>
                </div>
            </div>
            <TilbakeTil url={`/kunder`} tekst="Tilbake til kundeoversikt" />
            {kundeliste && kundeliste.length > 0 ? (
                <div className="page">
                    <div className="container">
                        <VelgKundeListe
                            kundeliste={kundeliste}
                            kundeansvarlige={kundeansvarlige}
                            selectMultiple={true}
                            selectedKunder={selectedKundeBrukerId}
                            onKundeSelected={onKundeSelected}
                        />
                    </div>

                    <div className="page">
                        <div>
                            <div className="container">
                                <h1>Knytt rådgivere</h1>
                            </div>
                        </div>
                        <div className="container">
                            <div>
                                <SelectInput
                                    label="RÅDGIVERE"
                                    firstOptionText="Alle"
                                    createFirstOption={true}
                                    name="kundeansvarlig-select"
                                    value={selectedRadgiverBrukerId}
                                    options={getVeilederOptions(radgivere)}
                                    focus=""
                                    type={InputElementEnum.select}
                                    onInputChange={(name, value) => onSelectedRadgiverChange(value)}
                                />
                            </div>
                            <div>
                                <div>
                                    <button className="btn btn--secondary" onClick={onKnyttRadgiver}>
                                        Knytt kunder til rådgiver
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                !loading && (
                    <div className="page">
                        <div className="container">Ingen kunder ble importert</div>
                    </div>
                )
            )}
        </div>
    );
}

function getVeilederOptions(radgivere: IRadgiver[]) {
    return (
        (
            radgivere?.map((radgiver) => {
                return {
                    text: `${radgiver.fornavn} ${radgiver.etternavn}`,
                    value: radgiver.brukerId,
                };
            }) as Array<ISelectInputOption>
        )
            // Remove duplicates
            ?.filter((x: ISelectInputOption, i: number, a: Array<ISelectInputOption>) => a.map((option) => option.value).indexOf(x.value) === i)
            // Sort alfabetically
            ?.sort((a: ISelectInputOption, b: ISelectInputOption) => a.text.localeCompare(b.text)) ?? []
    );
}
